import { Component } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

import { ActivityBriefViewProvider } from '../widgets/activity-brief'

export
function PostponeCouponActivityIndexViewProvider(SuperComponent, BaseComponent) {
  const PostponeCouponActivityBriefView = ActivityBriefViewProvider(BaseComponent)

  @Component()
  class PostponeCouponActivityIndexView extends SuperComponent {
    /** @override */
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)
      const { value: mainView } = root.query({ as: <div class="main-box" /> }).next()

      // mainView?.children?.clear?.()
      mainView?.children?.push(
        <PostponeCouponActivityBriefView type="postpone-coupon" title="延时券活动" fetch="/admin/api/delay/index_delay" />
      )

      return vnode
    }
  }

  return PostponeCouponActivityIndexView
}
