import { Component } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

import { ActivityBriefViewProvider } from '../widgets/activity-brief'

export
function RaffleActivityIndexViewProvider(SuperComponent, BaseComponent) {
  const RaffleActivityBriefView = ActivityBriefViewProvider(BaseComponent)

  @Component()
  class RaffleActivityIndexView extends SuperComponent {
    /** @override */
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)
      const { value: mainView } = root.query({ as: <div class="main-box" /> }).next()

      // mainView?.children?.clear?.()
      mainView?.children?.push(
        <RaffleActivityBriefView type="raffle" title="抽奖活动" fetch="/admin/api/prize/active_prize_list" />
      )

      return vnode
    }
  }

  return RaffleActivityIndexView
}
