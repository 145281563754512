import { Component } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ActivityHistoryViewProvider } from '../widgets/activity-history'

export
function VoteActivityHistoryViewProvider(SuperComponent, BaseComponent) {
  const ActivityHistoryView = ActivityHistoryViewProvider(BaseComponent)

  @Component()
  class VoteActivityHistoryView extends SuperComponent {
    isVoteActivity() {
      return (
        this.$route.query.type ? this.$route.query.type === 'vote' : this.$route.query.isVote
      )
    }

    /** @override */
    getActData(searchText) {
      if (this.isVoteActivity()) {
        // stub
        return
      }

      return super.getActData(searchText)
    }

    /** @override */
    render() {
      const vnode = super.render()

      if (this.isVoteActivity()) {
        const root = createVNodeProxy(vnode)
        const { value: mainView } = root.query({ as: <div class="main-page" />, parentAs: <div class="main-box" /> }).next()

        mainView?.before(
          <ActivityHistoryView type="vote" title="问券调查活动" fetch="/admin/api/operate/AnswerList?status=2" />
        )
        mainView?.remove()
      }

      return vnode
    }
  }

  return VoteActivityHistoryView
}
