import { Component, Prop } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

export
function ActivityDataExporterViewProvider(BaseComponent) {
  @Component()
  class ActivityDataExporterView extends BaseComponent {
    @Prop()
    type

    @Prop({ default: '入口数据分析' })
    title

    @Prop({ default: '入口数据分析' })
    title2

    @Prop({ default: [] })
    entries

    created() {
      // this.$delete(this._data, 'title')
    }

    mounted() {
      // stub
    }

    async exportOperationData(url) {
      try {
        const link = await Remote.get().http(url)

        // console.log(link)
        Remote.get().download(link)
      } catch (error) {
        this.$message.error(error.message ?? error)
      }
    }

    /** @override */
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)
      const { value: referenceView } = root.query({ as: <div class="main-page tableList" /> }).next()

      const titleView = referenceView?.firstElementChild?.()?.firstElementChild?.()

      if (!titleView) {
        return null
      }

      const textNode = titleView.firstChild()

      if (textNode?.nodeType === document.TEXT_NODE) {
        textNode.data = this.title2
      }

      const { value: exporterView } = referenceView.query({ as: <span class="export" /> }).next()

      if (exporterView) {
        let i = 0
        for (const entry of this.entries) {
          const cloned = exporterView.cloneNode(true)

          cloned.listeners.click = e => this.exportOperationData(entry.url)
          cloned.listeners.click.entry = entry

          const textNode = cloned.lastChild()

          if (textNode?.nodeType === document.TEXT_NODE) {
            textNode.data = entry.label
          }

          exporterView.after(cloned.vnode)
        }
      }

      exporterView?.remove()

      return titleView.vnode
    }
  }


  return ActivityDataExporterView
}
