import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { Remote } from '@/services/Remote'

import { Breadcrumb } from '@/widgets/Breadcrumb'

import { Container, Main, Pagination, Card, Row, Col, Input, Select, Option, Button, Table, TableColumn } from 'element-ui'

@Component()
export
class DeviceListView extends ReactiveComponent {
  tableData = []

  total = 0

  queryAgent = ''
  querySupervisor = ''
  queryNetwork = ''

  queryWord = ''

  queryPageNum = 1
  queryPageSize = 20

  agentOptions = []
  supervisorOptions = []
  networkOptions = []

  created() {
    this.loadAgentOptions()
    // this.loadSupervisorOptions()
    // this.loadNetworkOptions()

    this.loadData()
  }

  @Watch('queryAgent')
  @Watch('querySupervisor')
  @Watch('queryNetwork')
  @Watch('queryWord')
  @Watch('queryPageNum')
  @Watch('queryPageSize')
  async loadData() {
    const { data, total } = await Remote.get().http('/admin/api/device/index_device_list', {
      params: {
        agent_id: this.queryAgent,
        supervisor_id: this.querySupervisor,
        network_id: this.queryNetwork,

        name: this.queryWord,

        per_page: this.queryPageSize,
        page: this.queryPageNum,
      }
    })

    this.tableData = data
    this.total = total
  }

  async loadAgentOptions() {
    const data = await Remote.get().http('/admin/api/device/agent_list')

    this.agentOptions = data
  }

  @Watch('queryAgent')
  async loadSupervisorOptions() {
    if (!this.queryAgent) {
      return
    }

    const data = await Remote.get().http('/admin/api/device/supervisor_list', { params: { agent_id: this.queryAgent }})

    this.supervisorOptions = data

    // reset networkOptions
    this.networkOptions = []
  }

  @Watch('querySupervisor')
  async loadNetworkOptions() {
    if (!this.querySupervisor) {
      return
    }

    const data = await Remote.get().http('/admin/api/device/network_list', { params: { supervisor_id: this.querySupervisor }})

    this.networkOptions = data
  }

  @Watch('queryAgent')
  resetOptionsByAgent() {
    this.querySupervisor = ''
    this.queryNetwork = ''

    this.networkOptions = []
  }

  @Watch('querySupervisor')
  resetOptionsBySupervisor() {
    this.queryNetwork = ''
  }

  async exportData() {
    const url = await Remote.get().http('/admin/api/device/export_device_list', {
      params: {
        agent_id: this.queryAgent,
        supervisor_id: this.querySupervisor,
        network_id: this.queryNetwork,

        name: this.queryWord,

        per_page: this.queryPageSize,
        page: this.queryPageNum,
      }
    })

    Remote.get().download(url)
  }

  viewItem(item) {
    this.$router.push({ name: 'DeviceDetail', query: { id: item.id } })
  }

  render() {
    return (
      <Container class="device-list-main base-main">
        <Breadcrumb />

        <Main>
          <Card>
            <Row gutter={ 24 }>
              <Col span={ 2 }>
                <label for="search-word">设备号</label>
              </Col>
              <Col span={ 4 }>
                <Input label="设备号" placeholder="关键字搜索" id="search-word" value={ this.queryWord } onInput={ e => (this.queryWord = e) } suffix-icon="el-icon-search" clearable />
              </Col>

              <Col span={ 2 }>
                <label for="search-agent">代理商名称</label>
              </Col>
              <Col span={ 4 }>
                <Select label="代理商名称" placeholder="按代理商查询" id="search-agent" value={ this.queryAgent } onInput={ e => (this.queryAgent = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.agentOptions.map(agent => (
                      <Option value={ agent.id } label={ agent.name } key={ agent.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-supervisor">经销点名称</label>
              </Col>
              <Col span={ 4 }>
                <Select label="经销点名称" placeholder="按经销点查询" id="search-supervisor" value={ this.querySupervisor } onInput={ e => (this.querySupervisor = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.supervisorOptions.map(supervisor => (
                      <Option value={ supervisor.id } label={ supervisor.name } key={ supervisor.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-network">网点名称</label>
              </Col>
              <Col span={ 4 }>
                <Select label="网点名称" placeholder="按网点查询" id="search-network" value={ this.queryNetwork } onInput={ e => (this.queryNetwork = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.networkOptions.map(network => (
                      <Option value={ network.id } label={ network.name } key={ network.id } />
                    ))
                  }
                </Select>
              </Col>

            </Row>
          </Card>
          <Card>
            <Row slot="header">
              <Col span={ 2 }>
                <span class="label">网点数据</span>
              </Col>
              <Col span={ 2 } offset={ 20 }>
                <Button type="primary" onClick={ this.exportData.bind(this) }>导出数据</Button>
              </Col>
            </Row>
            <Table data={ this.tableData } stripe>
              <TableColumn label="设备名称/设备号" scopedSlots={{
                default: scope => (
                  scope.row.name ? `${scope.row.name}/${scope.row.device_code}` : scope.row.device_code
                )
              }} />
              <TableColumn label="代理商" prop="agent_name" />
              <TableColumn label="经销点" prop="supervisor_name" />
              <TableColumn label="网点" prop="network_name" />

              <TableColumn label="运行状态" prop="status_name" />
              <TableColumn label="在线状态" prop="online_name" />
              <TableColumn label="配置状态" prop="is_config_status" />

              <TableColumn label="操作" scopedSlots={{
                default: scope => (
                  <Button type="primary" onClick={ e => this.viewItem(scope.row) }>详情</Button>
                )
              }} />
            </Table>

            <div class="pagination block">
              <Pagination
                pageSize={ this.queryPageSize }
                total={ this.total }
                layout="total, prev, pager, next, sizes, jumper"

                on-current-change={ e => (this.queryPageNum = e) }
                on-size-change={ e => (this.queryPageSize = e) }
                on-prev-click={ e => (this.queryPageNum = e) }
                on-next-click={ e => (this.queryPageNum = e) }
              />
            </div>

          </Card>
        </Main>
      </Container>
    )
  }
}
