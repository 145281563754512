import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { Remote } from '@/services/Remote'

import { Breadcrumb } from '@/widgets/Breadcrumb'

import { Container, Main, Pagination, Card, Row, Col, Select, Option, Button, Table, TableColumn, RadioGroup, RadioButton, InputNumber, Descriptions, DescriptionsItem } from 'element-ui'

import { range } from 'lodash-es'

import './detail.css'

@Component()
export
class DeviceDetailView extends ReactiveComponent {
  deviceData = null
  recordDataAggregates = null

  queryCountAggregates = null

  queryRecordType = 1

  created() {
    if (!this.$route.query.id) {
      this.$router.push({ name: 'DeviceList' })

      return
    }

    this.loadData()
    this.loadRecordData()
  }

  get currentQueryCount() {
    return this.queryCountAggregates?.[this.queryRecordType] ?? 100
  }

  set currentQueryCount(count) {
    this.$set(this.queryCountAggregates ??= {}, this.queryRecordType, count)
  }

  get currentRecordData() {
    return this.recordDataAggregates?.[this.queryRecordType] ?? []
  }

  set currentRecordData(data) {
    this.$set(this.recordDataAggregates ??= {}, this.queryRecordType, data)
  }

  async loadData() {
    const data = await Remote.get().http('/admin/api/device/device_detail', {
      params: {
        device_id: this.$route.query.id,
      }
    })

    this.deviceData = data
  }

  @Watch('queryRecordType')
  @Watch('currentQueryCount')
  async loadRecordData() {
    if (this.queryRecordType === 1 || this.queryRecordType === 2) {
      const data = await Remote.get().http('/admin/api/device/device_online_detail', {
        params: {
          device_id: this.$route.query.id,
          type: this.queryRecordType,
          page: this.currentQueryCount,
        }
      })

      this.currentRecordData = data

      return
    }

    if (this.queryRecordType === 3) {
      const data = await Remote.get().http('/admin/api/device/device_order_detail', {
        params: {
          device_id: this.$route.query.id,
          page: this.currentQueryCount,
        }
      })

      this.currentRecordData = data

      return
    }
  }

  async exportRecordData() {
    if (this.queryRecordType === 1 || this.queryRecordType === 2) {
      const url = await Remote.get().http('/admin/api/device/export_device_online_detail', {
        params: {
          device_id: this.$route.query.id,
          type: this.queryRecordType,
          page: this.currentQueryCount,
        }
      })

      Remote.get().download(url)

      return
    }

    if (this.queryRecordType === 3) {
      const url = await Remote.get().http('/admin/api/device/export_device_order_detail', {
        params: {
          device_id: this.$route.query.id,
          page: this.currentQueryCount,
        }
      })

      Remote.get().download(url)

      return
    }
  }

  render() {
    return (
      <Container class="device-detail-main base-main">
        <Breadcrumb />

        <Main>
          <Card>
            <Descriptions column={ 3 }>
              <DescriptionsItem label="设备编号">{ this.deviceData?.device_code }</DescriptionsItem>
              <DescriptionsItem label="设备名称">{ this.deviceData?.name }</DescriptionsItem>
              <DescriptionsItem label="实时在线">{ this.deviceData?.online }</DescriptionsItem>

              <DescriptionsItem label="DTU编号" span={ 2 }>{ this.deviceData?.dtu_code }</DescriptionsItem>
              <DescriptionsItem label="注册上网时间">{ this.deviceData?.registerAt }</DescriptionsItem>

              <DescriptionsItem label="设备型号" span={ 2 }>{ this.deviceData?.model }</DescriptionsItem>
              <DescriptionsItem label="最近一次入网时间">{ this.deviceData?.last_join_at }</DescriptionsItem>

              <DescriptionsItem label="流量卡编号" span={ 2 }>{ this.deviceData?.sim_id }</DescriptionsItem>
              <DescriptionsItem label="最近一次离线时间">{ this.deviceData?.last_disconnect_at }</DescriptionsItem>
            </Descriptions>
          </Card>

          <Card>
            <Row slot="header">
              <RadioGroup value={ this.queryRecordType } onInput={ e => (this.queryRecordType = + e) } size="medium">
                <RadioButton label={ 1 }>上下线记录</RadioButton>
                <RadioButton label={ 2 }>状态记录</RadioButton>
                <RadioButton label={ 3 }>订单记录</RadioButton>
              </RadioGroup>
            </Row>


            <Row>
              <Col span={ 6 }>
                <Select value={ this.currentQueryCount } onInput={ e => (this.currentQueryCount = e) }>
                  {
                    range(/*min*/100, /*max*/1000, /*step*/100).map(value => (
                      <Option value={ value } label={ `最近 ${value} 条` } key={ value } />
                    ))
                  }
                </Select>
              </Col>
              <Col span={ 4 }>
                <Button type="primary" onClick={ this.exportRecordData.bind(this) }>导出</Button>
              </Col>
            </Row>

            {
              (this.queryRecordType === 1 || this.queryRecordType === 2) &&
              <Table data={ this.currentRecordData } stripe>
                <TableColumn label="设备编号" prop="device_code" />
                <TableColumn label="DTU编号" prop="dtuCode" />
                <TableColumn label="运行状态" prop="status_name" />
                <TableColumn label="上报时间" scopedSlots={{
                  default: scope => scope.row.gmt_time && new Date(scope.row.gmt_time * 1000).toLocaleString('sv-SE')
                }} />
              </Table>
            }

            {
              this.queryRecordType === 3 &&
              <Table data={ this.currentRecordData } stripe>
                <TableColumn label="订单号" prop="order_no" />
                <TableColumn label="DTU编号" prop="dtuCode" />
                <TableColumn label="套餐时间/分钟" prop="time" />
                <TableColumn label="支付时间" prop="gmt_pay" />
              </Table>
            }

          </Card>
        </Main>
      </Container>
    )
  }
}
