import { Component, Prop } from '@/commons/component/decorators'

import { Chart } from '@/widgets/Chart'

import { merge, assign, omit } from 'lodash-es'

import defaultOptions from './funnel-chart-defaults.json'

export
const FunnelChart = ({ props, data, parent }) => {
  return (
    <div class="chart-box" style="width: 85%; height: 100%; min-height: 400px; display: flex;" { ...omit(data, 'attrs') }>
      {/*<h3>流量数据</h3>*/}
      <div class="chart-container" style="width: 100%;">
        <Chart data={ processChartData(props.data) } />
      </div>
    </div>
  )

  function processChartData(data) {
    const options = merge({}, defaultOptions)

    assign(options.series?.[0], {
      data: data.map(item => ({
        name: item.name ?? item.label,
        value: item.value ?? item.data,
      })),
    })

    assign(options, {
      legend: { data: data.map(item => item.name ?? item.label) }
    })

    console.log(options)

    return options
  }
}
