import { Component, Prop } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

export
function ActivityHistoryViewProvider(BaseComponent) {
  @Component()
  class ActivityHistoryView extends BaseComponent {
    @Prop()
    type

    @Prop()
    title

    @Prop()
    fetch

    // prev states
    loading = false
    activities = []
    // for compat
    activities1 = []
    // for compat
    activities2 = []

    /** @override */
    async getActData() {
      this.loading = true

      let data

      try {
        data = await Remote.get().http(this.fetch, {
          params: {
            name: this.searchText,
          }
        })
      } catch (error) {
        this.$message.error(error.message ?? error)

        return
      } finally {
        this.loading = false
      }

      for (const item of data) {
        item.name ??= item.name ?? item.title
        item.gmt_start = item.gmt_start ?? item.b_time
        item.gmt_end = item.gmt_end ?? item.e_time
      }

      this.activities = data
    }

    /** @override */
    toDetail(id) {
      this.$router.push({
        name: 'ActivitiesDetail',
        query: {
          id,
          type: this.type,
          isPackageCard: 'true',
        }
      })
    }

    /** @override */
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: referenceView } = root.query({ as: <div class="main-page" />, parentAs: <div class="main-box" /> }).next()

      if (!referenceView) {
        return null
      }

      const { value: titleView } = referenceView.query({ as: <p />, parentAs: <div class="title" /> }).next()

      let textNode = titleView?.firstChild()

      if (textNode?.nodeType === document.TEXT_NODE) {
        textNode.data = this.title ?? '运营活动'
      }

      for (const routerLinkView of Array.from(referenceView.query({ as: <router-link /> }))) {
        const { to } = routerLinkView.props
        const path = to.path ?? to.name

        if (path === 'ActivitiesDetail') {
          to.query ??= {}
          to.query.type = this.type
          to.query.isPackageCard = 'true'
        }
      }

      return referenceView.vnode
    }
  }


  return ActivityHistoryView
}
