import { getAppRoot } from '@rc/vue-facade'

// export
// const root = await (() => {
//   const rootThunk = getAppRoot()

//   return {
//     then(onFullfill, onReject) {
//       rootThunk((err, root) => {
//         if (err) {
//           onReject(err)
//         } else {
//           onFullfill(root)
//         }
//       })
//     }
//   }
// })()

// use this hack since parcel has not supported top-level-await yet

export
let root = null

export
const rootThunk = getAppRoot()

rootThunk((err, result) => {
  if (!err) {
    root = result
  }
})
