import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch, Prop } from '@/commons/component/decorators'

import { Breadcrumb as ElBreadcrumb, BreadcrumbItem as ElBreadcrumbItem } from 'element-ui'

import { last } from 'lodash-es'

import './styles/breadcrumb.css'

@Component()
export
class Breadcrumb extends ReactiveComponent {
  @Prop()
  title

  get breadList() {
    return this.$route.matched
  }

  render() {
    return (
      <div class="breadcrumb-box pr bgcFFF pdlr32">
        <ElBreadcrumb class="hidden-xs-only">
          {
            this.breadList.map((item, index) => (
              <ElBreadcrumbItem to={{ path: item.path }} key={ index }>{ item.meta.title }</ElBreadcrumbItem>
            ))
          }
        </ElBreadcrumb>

        {
          this.title &&
          <p class="menu-now fs20 color333">{ tihs.title }</p>
        }

        {
          !this.title &&
          <p class="menu-now fs20 color333">
            { last(this.breadList)?.meta.title }
          </p>
        }

      </div>
    )
  }
}
