import { Component, Prop } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

export
function ActivityBriefViewProvider(BaseComponent) {
  @Component()
  class ActivityBriefView extends BaseComponent {
    @Prop()
    type

    @Prop()
    title

    @Prop()
    fetch

    // prev states
    loading = false
    activities = []
    // for compat
    activities1 = []
    // for compat
    activities2 = []

    /** @override */
    async getActData(status) {
      this.loading = true

      try {
        const data　= await Remote.get().http(this.fetch, {
          params: { status }
        })

        this.activities = data
        this.activities1 = data
        this.activities2 = data
      } catch (err) {
        this.$message.error(err.message ?? String(error))
      } finally {
        this.loading = false
      }
    }

    /** @override */
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)
      const { value: referenceView } = root.query({ as: <div class="main-page" /> }).next()

      if (!referenceView) {
        return null
      }

      const { value: titleView } = referenceView.query({ as: <p class="title" /> }).next()

      if (titleView) {
        const textView = titleView.firstChild()

        if (textView?.nodeType === document.TEXT_NODE) {
          textView.data = this.title
        }
      }

      for (const routerLinkView of Array.from(referenceView.query({ as: <router-link /> }))) {
        for (const routerLinkView of Array.from(referenceView.query({ as: <router-link /> }))) {
          const { to } = routerLinkView.props
          const path = to.path ?? to.name

          if (path === 'ActivitiesDetail' || path === 'ActivitiesHistory') {
            to.query ??= {}
            to.query.type = this.type
            to.query.isPackageCard = 'true'
          }
        }
      }

      return referenceView.vnode
    }
  }


  return ActivityBriefView
}
