import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { Remote } from '@/services/Remote'

import { Breadcrumb } from '@/widgets/Breadcrumb'

import { Container, Main, Pagination, Card, Row, Col, Select, Option, RadioGroup, RadioButton, Button, DatePicker, Table, TableColumn } from 'element-ui'

export
function SupervisorOrderDataViewProvider(SuperComponent, BaseComponent) {

  @Component()
  class SupervisorOrderDataView extends SuperComponent {

    render() {
      const vnode = super.render()

      return vnode
    }


  }
  return SupervisorOrderDataView
}
