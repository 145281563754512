import Vue from 'vue'

export const Pagination = Vue.component('ElPagination')
export const Dialog = Vue.component('ElDialog')
export const Autocomplete = Vue.component('ElAutocomplete')
export const Dropdown = Vue.component('ElDropdown')
export const DropdownMenu = Vue.component('ElDropdownMenu')
export const DropdownItem = Vue.component('ElDropdownItem')
export const Menu = Vue.component('ElMenu')
export const Submenu = Vue.component('ElSubmenu')
export const MenuItem = Vue.component('ElMenuItem')
export const MenuItemGroup = Vue.component('ElMenuItemGroup')
export const Input = Vue.component('ElInput')
export const InputNumber = Vue.component('ElInputNumber')
export const Radio = Vue.component('ElRadio')
export const RadioGroup = Vue.component('ElRadioGroup')
export const RadioButton = Vue.component('ElRadioButton')
export const Checkbox = Vue.component('ElCheckbox')
export const CheckboxButton = Vue.component('ElCheckboxButton')
export const CheckboxGroup = Vue.component('ElCheckboxGroup')
export const Switch = Vue.component('ElSwitch')
export const Select = Vue.component('ElSelect')
export const Option = Vue.component('ElOption')
export const OptionGroup = Vue.component('ElOptionGroup')
export const Button = Vue.component('ElButton')
export const ButtonGroup = Vue.component('ElButtonGroup')
export const Table = Vue.component('ElTable')
export const TableColumn = Vue.component('ElTableColumn')
export const DatePicker = Vue.component('ElDatePicker')
export const TimeSelect = Vue.component('ElTimeSelect')
export const TimePicker = Vue.component('ElTimePicker')
export const Popover = Vue.component('ElPopover')
export const Tooltip = Vue.component('ElTooltip')
export const Breadcrumb = Vue.component('ElBreadcrumb')
export const BreadcrumbItem = Vue.component('ElBreadcrumbItem')
export const Form = Vue.component('ElForm')
export const FormItem = Vue.component('ElFormItem')
export const Tabs = Vue.component('ElTabs')
export const TabPane = Vue.component('ElTabPane')
export const Tag = Vue.component('ElTag')
export const Tree = Vue.component('ElTree')
export const Alert = Vue.component('ElAlert')
export const Slider = Vue.component('ElSlider')
export const Icon = Vue.component('ElIcon')
export const Row = Vue.component('ElRow')
export const Col = Vue.component('ElCol')
export const Upload = Vue.component('ElUpload')
export const Progress = Vue.component('ElProgress')
export const Spinner = Vue.component('ElSpinner')
export const Badge = Vue.component('ElBadge')
export const Card = Vue.component('ElCard')
export const Rate = Vue.component('ElRate')
export const Steps = Vue.component('ElSteps')
export const Step = Vue.component('ElStep')
export const Carousel = Vue.component('ElCarousel')
export const Scrollbar = Vue.component('ElScrollbar')
export const CarouselItem = Vue.component('ElCarouselItem')
export const Collapse = Vue.component('ElCollapse')
export const CollapseItem = Vue.component('ElCollapseItem')
export const Cascader = Vue.component('ElCascader')
export const ColorPicker = Vue.component('ElColorPicker')
export const Transfer = Vue.component('ElTransfer')
export const Container = Vue.component('ElContainer')
export const Header = Vue.component('ElHeader')
export const Aside = Vue.component('ElAside')
export const Main = Vue.component('ElMain')
export const Footer = Vue.component('ElFooter')
export const Timeline = Vue.component('ElTimeline')
export const TimelineItem = Vue.component('ElTimelineItem')
export const Link = Vue.component('ElLink')
export const Divider = Vue.component('ElDivider')
export const Image = Vue.component('ElImage')
export const Calendar = Vue.component('ElCalendar')
export const Backtop = Vue.component('ElBacktop')
export const PageHeader = Vue.component('ElPageHeader')
export const CascaderPanel = Vue.component('ElCascaderPanel')
export const Avatar = Vue.component('ElAvatar')
export const Drawer = Vue.component('ElDrawer')
export const Popconfirm = Vue.component('ElPopconfirm')
export const CollapseTransition = Vue.component('ElCollapseTransition')

