import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

import { Table, TableColumn, Divider } from 'element-ui'

import { FunnelChart } from '../widgets/funnel-chart'

import { ActivityDataExporterViewProvider } from '../widgets/table-exporter'

export
function DiscountActivityDataViewProvider(SuperComponent, BaseComponent) {
  const DataExporterView = ActivityDataExporterViewProvider(BaseComponent)

  @Component()
  class DiscountActivityDataView extends SuperComponent {
    operateData = {}

    isDiscountActivity() {
      return (
        this.$route.query.type ? this.$route.query.type === 'discount' : this.$route.query.isDiscount
      )
    }

    /** @override */
    async getOperateData() {
      if (!this.isDiscountActivity()) {
        return super.getOperateData()
      }

      this.loading = true

      try {
        this.operateData = await Remote.get().http('/admin/api/discount/dsicount_data', {
          params: {
            id: this.$route.query.id,
            s_time: this.startTime,
            e_time: this.endTime,
          }
        })
        this.title = this.operateData?.discount?.name
      } catch (error) {
        this.$message.error(error.message ?? error)
      } finally {
        this.loading = false
      }
    }

    /** @override */
    render() {
      const vnode = super.render()

      if (this.$route.query.type === 'discount') {
        const root = createVNodeProxy(vnode)

        const { value: chartView } = root.query({ as: <div class="main-page chart" /> }).next()

        chartView?.children?.clear?.()

        chartView?.children?.push(
          <div class="chart-left">
            <div class="left-item">
              <span class="type">浏览人数</span>
              <h3 class="number">{ this.operateData?.total?.uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">订单量</span>
              <h3 class="number">{ this.operateData?.total?.order_total ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">下订人数</span>
              <h3 class="number">{ this.operateData?.total?.order_user_total ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">复购订单</span>
              <h3 class="number">{ this.operateData?.total?.distinct_order_total ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">复购人数</span>
              <h3 class="number">{ this.operateData?.total?.distinct_order_user_total ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">折扣订单</span>
              <h3 class="number">{ this.operateData?.total?.discount_order_total ?? 0 }</h3>
            </div>
          </div>
        )

        chartView?.children?.push(
          <FunnelChart class="chart-right" data={[
            {
              name: '浏览人数',
              value: this.operateData?.total?.uv,
            },
            {
              name: '订单量',
              value: this.operateData?.total?.order_total,
            },
            {
              name: '复购订单',
              value: this.operateData?.total?.distinct_order_total,
            },
            {
              name: '折扣订单',
              value: this.operateData?.total?.discount_order_total,
            },
          ]} />
        )

        const { value: titleView } = root.query({ as: <p class="title" /> }).next()

        titleView?.before(<DataExporterView title="入口数据分析3333" entries={[
          {
            url: `/admin/api/discount/export_discount_day_data?id=${this.$route.query.id}&s_time=${this.startTime}&e_time=${this.endTime}`,
            label: '导出数据 (按天)'
          },
          {
            url: `/admin/api/discount/export_discount_all_data?id=${this.$route.query.id}&s_time=${this.startTime}&e_time=${this.endTime}`,
            label: '导出数据 (总数)'
          }
        ]} />)
        titleView?.remove()

        const { value: tableBoxView } = root.query({ as: <div class="table-box" /> }).next()

        tableBoxView?.children?.clear?.()

        tableBoxView?.children?.push(
          <Table data={ this.operateData?.list ?? [] } headerCellStyle={{ textAlign: 'center' }} cellStyle={{ textAlign: 'center' }} tooltipEffect="dark" border={ false } highlightCurrentRow style={{ width: '100%' }}>
            <TableColumn label="经销点" prop="supervisor_name" width="150" />
            <TableColumn label="投放设备数" prop="device_total" />
            <TableColumn label="订单数" title="投放折扣区域所有的订单数">
              <TableColumn label="成功 + 退款" prop="order_total" />
              <TableColumn label="成功订单" prop="order_success" />
              <TableColumn label="退款订单" prop="refund_order" />
            </TableColumn>
            <TableColumn label="下单率/%" prop="order_ratio" title="订单数/UV" />
            <TableColumn label="复购单数" prop="distinct_order_total" title="不含退款订单，除第一单外的订单数" />
            <TableColumn label="折扣订单" prop="discount_order_total" title="a" />
            <TableColumn label="复购率" prop="distinct_rate" title="复购订单/成功订单 (消费两单以上用户)" />
            <TableColumn label="自用复" prop="distinct_self_total" title="a" />
            <TableColumn label="同行复购" prop="distinct_peer_total" title="上一单成功订单按摩时间未结束时，扫码另一台设备下单" />
            <TableColumn label="前15天下单率" prop="before_fifteen_order_rate" title="投放活动开始前15天下单率（下单数/uv）" />
            <TableColumn label="前15天复购率" prop="before_fifteen_distinct_rate" title="投放活动开始前15天下单率（复购订单数/成功订单）" />
          </Table>
        )

        tableBoxView?.children?.push(
          <Divider />
        )

        tableBoxView?.children?.push(
          <Table data={ this.operateData?.list ?? [] } headerCellStyle={{ textAlign: 'center' }} cellStyle={{ textAlign: 'center' }} tooltipEffect="dark" border={ false } highlightCurrentRow style={{ width: '100%' }}>
            <TableColumn label="经销点" prop="supervisor_name" width="150" />
            <TableColumn label="banner点击次数" prop="banner_click" />
            <TableColumn label="投放套餐页">
              <TableColumn label="PV" prop="pv" />
              <TableColumn label="UV" prop="uv" />
            </TableColumn>
            <TableColumn label="投放区域计时页">
              <TableColumn label="PV" prop="count_down_pv" />
              <TableColumn label="UV" prop="count_down_uv" />
            </TableColumn>
            <TableColumn label="宣传落地页">
              <TableColumn label="PV" prop="" />
              <TableColumn label="UV" prop="" />
            </TableColumn>
            <TableColumn label="点击弹窗数" prop="" />
          </Table>
        )

      }

      return vnode
    }
  }


  return DiscountActivityDataView
}
