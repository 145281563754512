import { Component } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

import { ActivityBriefViewProvider } from '../widgets/activity-brief'

export
function DiscountActivityIndexViewProvider(SuperComponent, BaseComponent) {
  const DiscountActivityBriefView = ActivityBriefViewProvider(BaseComponent)

  @Component()
  class DiscountActivityIndexView extends SuperComponent {
    /** @override */
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)
      const { value: mainView } = root.query({ as: <div class="main-box" /> }).next()

      // mainView?.children?.clear?.()
      mainView?.children?.push(
        <DiscountActivityBriefView type="discount" title="折扣活动" fetch="/admin/api/discount/discount_list" />
      )

      return vnode
    }
  }

  return DiscountActivityIndexView
}
