import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

import { Table, TableColumn } from 'element-ui'

import { LineChart } from '../widgets/line-chart'

import { ActivityDataExporterViewProvider } from '../widgets/table-exporter'

export
function RaffleActivityDataViewProvider(SuperComponent, BaseComponent) {
  const DataExporterView = ActivityDataExporterViewProvider(BaseComponent)

  @Component()
  class RaffleActivityDataView extends SuperComponent {
    operateData = {}

    isRaffleActivity() {
      return (
        this.$route.query.type ? this.$route.query.type === 'raffle' : this.$route.query.isRaffle
      )
    }

    /** @override */
    async getOperateData() {
      if (!this.isRaffleActivity()) {
        return super.getOperateData()
      }

      this.loading = true

      try {
        this.operateData = await Remote.get().http('/admin/api/prize/active_prize_data', {
          params: {
            id: this.$route.query.id,
            s_time: this.startTime,
            e_time: this.endTime,
          }
        })
        this.title = this.operateData?.name
      } catch (error) {
        this.$message.error(error.message ?? error)
      } finally {
        this.loading = false
      }
    }

    /** @override */
    render() {
      const vnode = super.render()

      if (this.$route.query.type === 'raffle') {
        const root = createVNodeProxy(vnode)

        const { value: chartView } = root.query({ as: <div class="main-page chart" /> }).next()

        chartView?.children?.clear?.()

        chartView?.children?.push(
          <div class="chart-left">
            <div class="left-item">
              <span class="type">套餐页UV</span>
              <h3 class="number">{ this.operateData?.index_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">套餐页PV</span>
              <h3 class="number">{ this.operateData?.index_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">计时页UV</span>
              <h3 class="number">{ this.operateData?.index_pv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">计时页PV</span>
              <h3 class="number">{ this.operateData?.down_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">活动介绍页UV</span>
              <h3 class="number">{ this.operateData?.down_pv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">活动介绍页PV</span>
              <h3 class="number">{ this.operateData?.detail_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">计时页进入活动页UV</span>
              <h3 class="number">{ this.operateData?.detail_pv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">中奖UV</span>
              <h3 class="number">{ this.operateData?.prize_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">中奖PV</span>
              <h3 class="number">{ this.operateData?.prize_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">未中奖UV</span>
              <h3 class="number">{ this.operateData?.not_prize_pv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">未中奖PV</span>
              <h3 class="number">{ this.operateData?.not_prize_uv ?? 0 }</h3>
            </div>
          </div>
        )

        chartView?.children?.push(
          <LineChart class="chart-right" data={{
            xAxis: {
              type: 'category',
              data: this.operateData.time_model,
            },

            series: [
              {
                name: '套餐页PV',
                data: this.operateData.index_model_pv,
              },
              {
                name: '套餐页UV',
                data: this.operateData.index_model_uv,
              },
              {
                name: '倒计时页PV',
                data: this.operateData.down_model_pv,
              },
              {
                name: '倒计时页UV',
                data: this.operateData.down_model_uv,
              },
            ]
          }} />
        )

        const { value: titleView } = root.query({ as: <p class="title" /> }).next()

        titleView?.before(<DataExporterView entries={[
          {
            url: `/admin/api/prize/export_active_prize_data?id=${this.$route.query.id}&s_time=${this.startTime}&e_time=${this.endTime}`,
            label: '导出复购参与抽奖数据（按天）'
          },
        ]} />)
        titleView?.remove()

        const { value: tableBoxView } = root.query({ as: <div class="table-box" /> }).next()

        tableBoxView?.children?.clear?.()

        if (this.operateData?.active_type === 1) {
          tableBoxView?.children?.push(
            <Table data={ this.operateData?.list ?? [] } headerCellStyle={{ textAlign: 'center' }} cellStyle={{ textAlign: 'center' }} tooltipEffect="dark" border={ false } highlightCurrentRow style={{ width: '100%' }}>
              <TableColumn label="经销点" prop="supervisor_name" width="150" />
              <TableColumn label="活动介绍页">
                <TableColumn label="PV" prop="detail_pv" />
                <TableColumn label="UV" prop="detail_uv" />
                <TableColumn label="点击跳转公众号按钮" prop="gzh_pv" />
              </TableColumn>
              <TableColumn label="计时页">
                <TableColumn label="PV" prop="down_pv" />
                <TableColumn label="UV" prop="down_uv" />
                <TableColumn label="点击弹窗次数" prop="prize_click" />
              </TableColumn>
              <TableColumn label="中奖页">
                <TableColumn label="PV" prop="prize_pv" />
                <TableColumn label="UV" prop="prize_uv" />
                <TableColumn label="点击跳转公众号按钮" prop="success_gzh_pv" />
              </TableColumn>
              <TableColumn label="未中奖页">
                <TableColumn label="PV" prop="not_prize_pv" />
                <TableColumn label="UV" prop="not_prize_uv" />
                <TableColumn label="点击跳转公众号按钮" prop="fail_gzh_pv" />
              </TableColumn>
              <TableColumn label="复购用户数" prop="distinct_user_total" />
              <TableColumn label="复购平均单价" prop="distinct_avg_price" />
              <TableColumn label="复购科均单数" prop="distinct_avg_total" />
            </Table>
          )
        }

        if (this.operateData?.active_type === 2) {
          tableBoxView?.children?.push(
            <Table data={ this.operateData?.list ?? [] } headerCellStyle={{ textAlign: 'center' }} cellStyle={{ textAlign: 'center' }} tooltipEffect="dark" border={ false } highlightCurrentRow style={{ width: '100%' }}>
              <TableColumn label="经销点" prop="supervisor_name" width="150" />
              <TableColumn label="抽奖页">
                <TableColumn label="PV" prop="detail_pv" />
                <TableColumn label="UV" prop="detail_uv" />
                <TableColumn label="点击抽奖按钮次数" prop="get_coupon_pv" />
              </TableColumn>
              <TableColumn label="中奖页">
                <TableColumn label="PV" prop="prize_pv" />
                <TableColumn label="UV" prop="prize_uv" />
                <TableColumn label="点击跳转公众号按钮" prop="success_gzh_pv" />
              </TableColumn>
              <TableColumn label="未中奖页">
                <TableColumn label="PV" prop="not_prize_pv" />
                <TableColumn label="UV" prop="not_prize_uv" />
                <TableColumn label="点击跳转公众号按钮" prop="fail_gzh_pv" />
              </TableColumn>
              <TableColumn label="领劵使用量" prop="coupon_use" />
            </Table>
          )
        }

      }

      return vnode
    }
  }


  return RaffleActivityDataView
}
