import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

import { Table, TableColumn } from 'element-ui'

import { LineChart } from '../widgets/line-chart'

import { ActivityDataExporterViewProvider } from '../widgets/table-exporter'

import '../data.css'

export
function VoteActivityDataViewProvider(SuperComponent, BaseComponent) {
  const DataExporterView = ActivityDataExporterViewProvider(BaseComponent)

  @Component()
  class VoteActivityDataView extends SuperComponent {
    operateData = {}

    isVoteActivity() {
      return (
        this.$route.query.type ? this.$route.query.type === 'vote' : this.$route.query.isVote
      )
    }

    /** @override */
    async getOperateData() {
      if (!this.isVoteActivity()) {
        return super.getOperateData()
      }

      this.loading = true

      try {
        this.operateData = await Remote.get().http('/admin/api/operate/OperateAnswerData', {
          params: {
            id: this.$route.query.id,
            vote_id: this.$route.query.id,
            s_time: this.startTime,
            e_time: this.endTime,
          }
        })
      } catch (error) {
        this.$message.error(error.message ?? error)
      } finally {
        this.loading = false
      }
    }

    /** @override */
    render() {
      const vnode = super.render()

      if (this.$route.query.type === 'vote') {
        const root = createVNodeProxy(vnode)

        const { value: chartView } = root.query({ as: <div class="main-page chart" /> }).next()

        chartView?.children?.clear?.()

        chartView?.children?.push(
          <div class="chart-left">
            <div class="left-item">
              <span class="type">套餐页UV</span>
              <h3 class="number">{ this.operateData?.index_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">套餐页问卷</span>
              <h3 class="number">{ this.operateData?.banner_total ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">倒计时页UV</span>
              <h3 class="number">{ this.operateData?.count_down_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">倒计时页问卷</span>
              <h3 class="number">{ this.operateData?.count_down_total ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">banner问卷进入UV</span>
              <h3 class="number">{ this.operateData?.vote_banner_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">倒计时进入问卷UV</span>
              <h3 class="number">{ this.operateData?.vote_count_uv ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">总问卷量</span>
              <h3 class="number">{ this.operateData?.answer_total ?? 0 }</h3>
            </div>
          </div>
        )

        chartView?.children?.push(
          <LineChart class="chart-right" data={{
            xAxis: {
              data: this.operateData.time_model,
            },

            series: [
              {
                name: '套餐页UV',
                data: this.operateData.index_model,
              },
              {
                name: '套餐页问卷',
                data: this.operateData.banner_model,
              },
              {
                name: '倒计时页UV',
                data: this.operateData.count_down_uv_model,
              },
              {
                name: '倒计时页问卷',
                data: this.operateData.count_down_model,
              },
              {
                name: 'banner问卷进入UV',
                data: this.operateData.vote_banner_uv_model,
              },
              {
                name: '倒计时进入问卷UV',
                data: this.operateData.vote_count_uv_model,
              }
            ]
          }} />
        )

        const { value: tableBoxView } = root.query({ as: <div class="table-box" /> }).next()

        const toArray = data => (
          Array.isArray(data) ? data :
          typeof data === 'object' && data ? Object.values(data) : []
        )

        tableBoxView?.children?.clear?.()
        tableBoxView?.children?.push(
          <Table data={ toArray(this.operateData?.list) } headerCellStyle={{ textAlign: 'center' }} cellStyle={{ textAlign: 'center' }} tooltipEffect="dark" border={ false } highlightCurrentRow style={{ width: '100%' }}>
            <TableColumn label="场景入口" prop="name" width="150" />
            <TableColumn label="设备数量" prop="device_total" />
            <TableColumn label="问卷数量" prop="answer_total" />

            <TableColumn label="套餐页">
              <TableColumn label="问卷数量" prop="banner_answer" />
            </TableColumn>
            <TableColumn label="倒计时页">
              <TableColumn label="问卷数量" prop="count_down_answer" />
            </TableColumn>

            <TableColumn label="延时券">
              <TableColumn label="领取总数" prop="ys_coupon_total" />
              <TableColumn label="获券后第一单使用" prop="ys_coupon_first_use" />
              <TableColumn label="第一单使用占比" scopedSlots={{
                default: scope => scope.row.ys_coupon_total ? scope.row.ys_coupon_first_use / scope.row.ys_coupon_total : 0
              }} />
            </TableColumn>
            <TableColumn label="优惠券">
              <TableColumn label="领取总数" prop="yh_coupon_total" />
              <TableColumn label="获券后第一单使用" prop="yh_coupon_first_use" />
              <TableColumn label="第一单使用占比" scopedSlots={{
                default: scope => scope.row.yh_coupon_total ? scope.row.yh_coupon_first_use / scope.row.yh_coupon_total : 0
              }} />
            </TableColumn>
          </Table>
        )
      }

      return vnode
    }
  }


  return VoteActivityDataView
}
