import { Component } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

import { ActivityBriefViewProvider } from '../widgets/activity-brief'

export
function FreeOrderActivityIndexViewProvider(SuperComponent, BaseComponent) {
  const FreeOrderActivityBriefView = ActivityBriefViewProvider(BaseComponent)

  @Component()
  class FreeOrderActivityIndexView extends SuperComponent {
    /** @override */
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)
      const { value: mainView } = root.query({ as: <div class="main-box" /> }).next()

      // mainView?.children?.clear?.()
      mainView?.children?.push(
        <FreeOrderActivityBriefView type="free-order" title="免单活动" fetch="/admin/api/free/free_list" />
      )

      return vnode
    }
  }

  return FreeOrderActivityIndexView
}
