import { Component } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ActivityHistoryViewProvider } from '../widgets/activity-history'

export
function DiscountActivityHistoryViewProvider(SuperComponent, BaseComponent) {
  const ActivityHistoryView = ActivityHistoryViewProvider(BaseComponent)

  @Component()
  class DiscountActivityHistoryView extends SuperComponent {
    isDiscountActivity() {
      return (
        this.$route.query.type ? this.$route.query.type === 'discount' : this.$route.query.isDiscount
      )
    }

    /** @override */
    getActData(searchText) {
      if (this.isDiscountActivity()) {
        // stub
        return
      }

      return super.getActData(searchText)
    }

    /** @override */
    render() {
      const vnode = super.render()

      if (this.isDiscountActivity()) {
        const root = createVNodeProxy(vnode)
        const { value: mainView } = root.query({ as: <div class="main-page" />, parentAs: <div class="main-box" /> }).next()

        mainView?.before(
          <ActivityHistoryView type="discount" title="折扣活动" fetch="/admin/api/discount/discount_list?status=99" />
        )
        mainView?.remove()
      }

      return vnode
    }
  }

  return DiscountActivityHistoryView
}
