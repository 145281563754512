import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

import { Table, TableColumn } from 'element-ui'

import { LineChart } from '../widgets/line-chart'

import { ActivityDataExporterViewProvider } from '../widgets/table-exporter'

export
function FreeOrderActivityDataViewProvider(SuperComponent, BaseComponent) {
  const DataExporterView = ActivityDataExporterViewProvider(BaseComponent)

  @Component()
  class FreeOrderActivityDataView extends SuperComponent {
    operateData = {}

    isFreeOrderActivity() {
      return (
        this.$route.query.type ? this.$route.query.type === 'free-order' : this.$route.query.isFreeOrder
      )
    }

    /** @override */
    async getOperateData() {
      if (!this.isFreeOrderActivity()) {
        return super.getOperateData()
      }

      this.loading = true

      try {
        this.operateData = await Remote.get().http('/admin/api/free/active_data', {
          params: {
            id: this.$route.query.id,
            s_time: this.startTime,
            e_time: this.endTime,
          }
        })
        this.title = this.operateData?.name
      } catch (error) {
        this.$message.error(error.message ?? error)
      } finally {
        this.loading = false
      }
    }

    /** @override */
    render() {
      const vnode = super.render()

      if (this.$route.query.type === 'free-order') {
        const root = createVNodeProxy(vnode)

        const { value: chartView } = root.query({ as: <div class="main-page chart" /> }).next()

        chartView?.children?.clear?.()

        chartView?.children?.push(
          <div class="chart-left">
            <div class="left-item">
              <span class="type">已领取</span>
              <h3 class="number">{ this.operateData.get_total ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">已领取用户</span>
              <h3 class="number">{ this.operateData.get_user_total ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">未使用</span>
              <h3 class="number">{ this.operateData.not_use_total ?? 0 }</h3>
            </div>
            <div class="left-item">
              <span class="type">已使用</span>
              <h3 class="number">{ this.operateData.use_total ?? 0 }</h3>
            </div>
          </div>
        )

        chartView?.children?.push(
          <LineChart class="chart-right" data={{
            xAxis: {
              data: this.operateData.time_model,
            },

            series: [
              {
                name: '套餐页UV',
                data: this.operateData.index_model,
              },
              {
                name: '套餐页UV',
                data: this.operateData.index_model_uv,
              },
              {
                name: '倒计时PV',
                data: this.operateData.down_model_pv,
              },
              {
                name: '倒计时UV',
                data: this.operateData.down_model_uv,
              },
            ]
          }} />
        )

        const { value: titleView } = root.query({ as: <p class="title" /> }).next()

        titleView?.before(<DataExporterView entries={[
          {
            url: `/admin/api/free/export_free_data?id=${this.$route.query.id}&status=0&s_time=${this.startTime}&e_time=${this.endTime}`,
            label: '导出未使用数据 (按天)'
          },
          {
            url: `/admin/api/free/export_free_data?id=${this.$route.query.id}&status=1&s_time=${this.startTime}&e_time=${this.endTime}`,
            label: '导出已使用数据 (按天)'
          },
        ]} />)
        titleView?.remove()

        const { value: tableBoxView } = root.query({ as: <div class="table-box" /> }).next()

        tableBoxView?.children?.clear?.()
        tableBoxView?.children?.push(
          <Table data={ this.operateData?.list ?? [] } headerCellStyle={{ textAlign: 'center' }} cellStyle={{ textAlign: 'center' }} tooltipEffect="dark" border={ false } highlightCurrentRow style={{ width: '100%' }}>
            <TableColumn label="日期" prop="created_at" width="150" />
            <TableColumn label="用户ID" prop="user_id" />
            <TableColumn label="活动名称" prop="active_id" />
            <TableColumn label="领取时间" prop="created_at" />
            <TableColumn label="使用时间" prop="use_time" />
            <TableColumn label="订单ID" prop="free_order_id" />
            <TableColumn label="过期时间" prop="over_time" />
            <TableColumn label="经销点" prop="supervisor_name" />
            <TableColumn label="设备号" prop="device_code" />
          </Table>
        )

      }

      return vnode
    }
  }


  return FreeOrderActivityDataView
}
