import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { Remote } from '@/services/Remote'

import { Breadcrumb } from '@/widgets/Breadcrumb'

import { Container, Main, Pagination, Card, Row, Col, Select, Option, RadioGroup, RadioButton, Button, DatePicker, Table, TableColumn } from 'element-ui'

@Component()
export
class SupervisorOfflineDeviceDataView extends ReactiveComponent {
  tableData = []

  total = 0

  querySupervisor = ''

  queryTimeHours = ''
  queryTimeRange = []
  queryStartTime = ''
  queryEndTime = ''

  queryPageNum = 1
  queryPageSize = 20

  supervisorOptions = []

  created() {
    this.loadSupervisorOptions()

    this.loadData()
  }

  @Watch('querySupervisor')
  @Watch('queryTimeHours')
  @Watch('queryTimeRange.0')
  @Watch('queryTimeRange.1')
  @Watch('queryPageNum')
  @Watch('queryPageSize')
  async loadData() {
    const { data, total } = await Remote.get().http('/admin/api/operation/get_online_info', {
      params: {
        supervisor_id: this.querySupervisor,

        hours: this.queryTimeHours,

        b_time: this.queryTimeRange?.[0]?.toLocaleString?.('sv-SE'),
        e_time: this.queryTimeRange?.[1]?.toLocaleString?.('sv-SE'),

        per_page: this.queryPageSize,
        page: this.queryPageNum,
      }
    })

    this.tableData = data
    this.total = total
  }

  async exportData() {
    const url = await Remote.get().http('/admin/api/operation/export_online_info', {
      params: {
        supervisor_id: this.querySupervisor,

        hours: this.queryTimeHours,

        b_time: this.queryTimeRange?.[0]?.toLocaleString?.('sv-SE'),
        e_time: this.queryTimeRange?.[1]?.toLocaleString?.('sv-SE'),

        per_page: this.queryPageSize,
        page: this.queryPageNum,
      }
    })

    Remote.get().download(url)
  }

  async loadSupervisorOptions() {
    const data = await Remote.get().http('/admin/api/operation/get_supevisor')

    this.supervisorOptions = data
  }

  render() {
    return (
      <Container class="supervisor-offline-data-main base-main">
        <Breadcrumb />

        <Main>
          <Card>
            <Row gutter={ 24 }>
              <Col span={ 2 }>
                <label for="search-supervisor">经销点名称</label>
              </Col>
              <Col span={ 4 }>
                <Select label="经销点名称" placeholder="请输入" id="search-supervisor" value={ this.querySupervisor } onInput={ e => (this.querySupervisor = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.supervisorOptions.map(supervisor => (
                      <Option value={ supervisor.id } label={ supervisor.name } key={ supervisor.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-timerange">时间段</label>
              </Col>
              <Col span={ 12 }>
                <DatePicker type="daterange" value={ this.queryTimeRange } onInput={ e => (this.queryTimeRange  = e) } range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-right: 1em" />
                <RadioGroup value={ this.queryTimeHours } onInput={ e => (this.queryTimeHours = + e) } size="small">
                  <RadioButton label={ 8 }>8时</RadioButton>
                  <RadioButton label={ 12 }>12时</RadioButton>
                  <RadioButton label={ 16 }>16时</RadioButton>
                  <RadioButton label={ 20 }>20时</RadioButton>
                  <RadioButton label={ 0 }>0时</RadioButton>
                </RadioGroup>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row slot="header">
              <Col span={ 2 }>
                <span class="label">经销点数据</span>
              </Col>
              <Col span={ 2 } offset={ 20 }>
                <Button type="primary" onClick={ this.exportData.bind(this) }>导出数据</Button>
              </Col>
            </Row>
            <Table data={ this.tableData } stripe>
              <TableColumn label="经销点名称" prop="supervisor_name" />
              <TableColumn label="网点数量" prop="network_total" />
              <TableColumn label="所属行政区" prop="s_city" />
              <TableColumn label="网点所属地区" prop="n_city" />
              <TableColumn label="场地类型" prop="site_name" />
              <TableColumn label="设备数量" prop="device_num" />
              <TableColumn label="在线设备数量" prop="online_num" />
              <TableColumn label="离线设备数量" prop="offline_num" />
              <TableColumn label="故障设备数量" prop="fault_num" />
            </Table>

            <div class="pagination block">
              <Pagination
                pageSize={ this.queryPageSize }
                total={ this.total }
                layout="total, prev, pager, next, sizes, jumper"

                on-current-change={ e => (this.queryPageNum = e) }
                on-size-change={ e => (this.queryPageSize = e) }
                on-prev-click={ e => (this.queryPageNum = e) }
                on-next-click={ e => (this.queryPageNum = e) }
              />
            </div>

          </Card>
        </Main>
      </Container>
    )
  }
}
