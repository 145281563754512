{
  "title": {
    "text": "Funnel",
    "show": false
  },
  "tooltip": {
    "trigger": "item",
    "formatter": "{b} : {c}"
  },
  "toolbox": {
    "feature": {
      "dataView": { "readOnly": false },
      "restore": {},
      "saveAsImage": {}
    }
  },
  "series": [
    {
      "name": "Funnel",
      "type": "funnel",
      "left": "10%",
      "top": 60,
      "bottom": 60,
      "width": "80%",
      "min": 0,
      "minSize": "0%",
      "maxSize": "100%",
      "sort": "descending",
      "gap": 2,
      "label": {
        "show": true,
        "position": "inside"
      },
      "labelLine": {
        "length": 10,
        "lineStyle": {
          "width": 1,
          "type": "solid"
        }
      },
      "itemStyle": {
        "borderColor": "#fff",
        "borderWidth": 1
      },
      "emphasis": {
        "label": {
          "fontSize": 20
        }
      }
    }
  ]
}
