import { Chart } from '@/widgets/Chart'

export
const PieChart = ({ props }) => {
  const { data, title, width, height } = props

  const list = data.filter(item => item.rate < 1)
  const total = data.find(item => item.rate === 1) ?? { name: '总数', total: list.reduce((r, v) => r + v.total, 0) }

  const hue = 60
  const sat = 80
  const light = 60
  const step = 20

  console.log(title)

  return (
    <Chart
      width={ width }
      height={ height }

      data={{
        title: {
          text: total?.name,
          textStyle: {
            color: '#606266',
            fontSize: 16,
          },
          subtext: total?.total,
          subtextStyle: {
            color: '#303133',
            fontSize: 18,
          },
          // top: '50%',
          x: 'center',
          y: 'center',
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{b} : {c} ({d}%)',
          formatter: '{b}',
        },
        color: list.map((item, i) => `hsl(${hue + i * 20}, ${sat}%, ${light}%)`),
        grid: {
          top: '3%',
          left: '4%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        series: [
          {
            name: title,
            type: 'pie',
            radius: [ '40%', '70%' ],
            center: [ '50%', '50%' ],
            emphasis: {
              label: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                borderWidth: 2,
                borderColor: '#fff',
              },
            },
            labelLine: {
              normal: {
                length: 10,
                lineStyle: {
                  color: '#333',
                },
              },
            },
            label: {
              normal: {
                textStyle: {
                  color: '#333',
                },
              },
            },
            data: list.map(item => {
              return {
                value: item.total,
                name: `${item.name}: \n${item.total} (${item.rate}%)`,
              }
            }),
          },
        ],
      }}
    />
  )
}
