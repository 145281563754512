import { Component, Prop } from '@/commons/component/decorators'

import { Chart } from '@/widgets/Chart'

import { merge, assign, omit } from 'lodash-es'

export
const LineChart = ({ props, data, parent }) => {
  return (
    <div class="chart-box" style="width: 85%; height: 100%; min-height: 400px; display: flex;" { ...omit(data, 'attrs') }>
      {/*<h3>流量数据</h3>*/}
      <div class="chart-container" style="width: 100%;">
        <Chart data={ processChartData(props.data) } />
      </div>
    </div>
  )

  function processChartData(data) {
    const options = merge({}, parent.CardChartOption)

    assign(options, {
      xAxis: {
        type: 'category',
        data: data.xAxis.data ?? data.xAxis,
      },

      yAxis: {
        type: 'value'
      },

      series: data.series.map(item => {
        item.type ??= 'line'

        return item
      }),

      legend: { data: data.series.map(item => item.name) }
    })

    return options
  }
}
