import { VoteActivityIndexViewProvider } from '@/pages/stats/activities/vote/index'
import { RaffleActivityIndexViewProvider } from '@/pages/stats/activities/raffle/index'
import { PostponeCouponActivityIndexViewProvider } from '@/pages/stats/activities/postpone-coupon/index'
import { DiscountActivityIndexViewProvider } from '@/pages/stats/activities/discount/index'
import { FreeOrderActivityIndexViewProvider } from '@/pages/stats/activities/free-order/index'

import { VoteActivityHistoryViewProvider } from '@/pages/stats/activities/vote/history'
import { RaffleActivityHistoryViewProvider } from '@/pages/stats/activities/raffle/history'
import { PostponeCouponActivityHistoryViewProvider } from '@/pages/stats/activities/postpone-coupon/history'
import { DiscountActivityHistoryViewProvider } from '@/pages/stats/activities/discount/history'
import { FreeOrderActivityHistoryViewProvider } from '@/pages/stats/activities/free-order/history'

import { VoteActivityDataViewProvider } from '@/pages/stats/activities/vote/data'
import { RaffleActivityDataViewProvider } from '@/pages/stats/activities/raffle/data'
import { PostponeCouponActivityDataViewProvider } from '@/pages/stats/activities/postpone-coupon/data'
import { DiscountActivityDataViewProvider } from '@/pages/stats/activities/discount/data'
import { FreeOrderActivityDataViewProvider } from '@/pages/stats/activities/free-order/data'

import { SupervisorOrderDataView } from '@/pages/operate/data/index'
import { SupervisorOrderDataViewProvider } from '@/pages/operate/data/supervisor-order'
import { SupervisorOfflineDeviceDataView } from '@/pages/operate/offline/index'
import { SupervisorRepairDataView } from '@/pages/operate/fault/index'

import { DeviceListView } from '@/pages/device/index'
import { DeviceDetailView } from '@/pages/device/detail'



export
const routes = [
  {
    path: '/admin',

    children: [
      {
        path: 'PvUvStatistics',

        children: [
          {
            name: 'Activities',
            provider: [
              VoteActivityIndexViewProvider,
              RaffleActivityIndexViewProvider,
              PostponeCouponActivityIndexViewProvider,
              DiscountActivityIndexViewProvider,
              FreeOrderActivityIndexViewProvider,
            ]
          },

          {
            name: 'ActivitiesHistory',
            provider: [
              VoteActivityHistoryViewProvider,
              RaffleActivityHistoryViewProvider,
              PostponeCouponActivityHistoryViewProvider,
              DiscountActivityHistoryViewProvider,
              FreeOrderActivityHistoryViewProvider,
            ]
          },

          {
            name: 'ActivitiesDetail',
            provider: [
              VoteActivityDataViewProvider,
              RaffleActivityDataViewProvider,
              PostponeCouponActivityDataViewProvider,
              DiscountActivityDataViewProvider,
              FreeOrderActivityDataViewProvider,
            ]
          },
        ]

      },
      {
        path:'SupervisorOrder',
        children:[
          {
            name:'SupervisorOrder',
            provider:[
              SupervisorOrderDataViewProvider
            ]
          },
               {
            name: 'SupervisorRepair',
            path: '/admin/SupervisorRepair',

            component: SupervisorRepairDataView,

            meta: { title: '运维分析' },
          },

          {
            name: 'SupervisorOffline',
            path: '/admin/SupervisorOffline',

            component: SupervisorOfflineDeviceDataView,

            meta: { title: '离线分析' },
          },
        ]
      },
      // {
      //   path: 'OperateStatistics',

      //   component: {
      //     render() {
      //       return <router-view />
      //     }
      //   },

      //   redirect: { name: 'SupervisorOrder' },

      //   meta: { title: '运营分析' },

      //   children: [
      //     {
      //       name: 'SupervisorOrder',
      //       path: '/admin/SupervisorOrder',

      //       component: SupervisorOrderDataView,

      //       meta: { title: '数据分析' },
      //     },

      //     {
      //       name: 'SupervisorRepair',
      //       path: '/admin/SupervisorRepair',

      //       component: SupervisorRepairDataView,

      //       meta: { title: '运维分析' },
      //     },

      //     {
      //       name: 'SupervisorOffline',
      //       path: '/admin/SupervisorOffline',

      //       component: SupervisorOfflineDeviceDataView,

      //       meta: { title: '离线分析' },
      //     },
      //   ]
      // },

      {
        path: 'Device',

        component: {
          render() {
            return <router-view />
          }
        },

        redirect: { name: 'DeviceList' },

        meta: { title: '设备分析' },

        children: [
          {
            name: 'DeviceList',
            path: '/admin/DeviceList',

            component: DeviceListView,

            meta: { title: '设备列表' },
          },

          {
            name: 'DeviceDetail',
            path: '/admin/DeviceDetail',

            component: DeviceDetailView,

            meta: { title: '设备详情' },
          },
        ]
      }
    ]
  }
]
