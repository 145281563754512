import { Component } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { Remote } from '@/services/Remote'

import { ActivityBriefViewProvider } from '../widgets/activity-brief'

export
function VoteActivityIndexViewProvider(SuperComponent, BaseComponent) {
  const VoteActivityBriefView = ActivityBriefViewProvider(BaseComponent)

  @Component()
  class VoteActivityIndexView extends SuperComponent {
    /** @override */
    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)
      const { value: mainView } = root.query({ as: <div class="main-box" /> }).next()

      // mainView?.children?.clear?.()
      mainView?.children?.push(
        <VoteActivityBriefView type="vote" title="问券调查活动" fetch="/admin/api/operate/AnswerList" />
      )

      return vnode
    }
  }

  return VoteActivityIndexView
}
