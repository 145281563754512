import { Component } from '@/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ActivityHistoryViewProvider } from '../widgets/activity-history'

export
function FreeOrderActivityHistoryViewProvider(SuperComponent, BaseComponent) {
  const ActivityHistoryView = ActivityHistoryViewProvider(BaseComponent)

  @Component()
  class FreeOrderActivityHistoryView extends SuperComponent {
    isFreeOrderActivity() {
      return (
        this.$route.query.type ? this.$route.query.type === 'free-order' : this.$route.query.isFreeOrder
      )
    }

    /** @override */
    getActData(searchText) {
      if (this.isFreeOrderActivity()) {
        // stub
        return
      }

      return super.getActData(searchText)
    }

    /** @override */
    render() {
      const vnode = super.render()

      if (this.isFreeOrderActivity()) {
        const root = createVNodeProxy(vnode)
        const { value: mainView } = root.query({ as: <div class="main-page" />, parentAs: <div class="main-box" /> }).next()

        mainView?.before(
          <ActivityHistoryView type="free-order" title="免单活动" fetch="/admin/api/free/free_list?status=99" />
        )
        mainView?.remove()
      }

      return vnode
    }
  }

  return FreeOrderActivityHistoryView
}
