import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { Remote } from '@/services/Remote'

import { Breadcrumb } from '@/widgets/Breadcrumb'

import { Container, Main, Pagination, Card, Row, Col, Select, Option, RadioGroup, RadioButton, Button, DatePicker, Table, TableColumn } from 'element-ui'

@Component()
export
class SupervisorOrderDataView extends ReactiveComponent {
  tableData = []

  total = 0

  queryAgent = []
  queryChannel = []
  queryDistrict = []
  queryRegion = []
  querySupervisor = []
  querySite = []
  queryPartner = ''
  queryType = [1,2]

  queryTimeRangeType = ''
  queryTimeRange = []

  queryPageNum = 1
  queryPageSize = 20

  agentOptions = []
  channelOptions = []
  districtOptions = []
  regionOptions = []
  supervisorOptions = []
  siteOptions = []
  partnerOptions = []
  typeOptions = [{ id: 1, name: "小按摩椅", }, { id: 2, name: "大按摩椅"},]

  created() {
    this.loadAgentOptions()
    this.loadDistrictOptions()
    this.loadSupervisorOptions()
    // this.loadPartnerOptions()

    this.loadData()
  }

  @Watch('queryAgent')
  @Watch('queryChannel')
  @Watch('queryDistrict')
  @Watch('queryRegion')
  @Watch('querySupervisor')
  @Watch('querySite')
  @Watch('queryPartner')
  @Watch('queryTimeRangeType')
  @Watch('queryTimeRange.0')
  @Watch('queryTimeRange.1')
  @Watch('queryPageNum')
  @Watch('queryPageSize')
  @Watch('queryType')
  async loadData() {
    const { data, total } = await Remote.get().http('/admin/api/operation/index_order_list', {
      params: {
        agent_id: this.queryAgent.filter(Boolean),
        supervisor_id: this.querySupervisor.filter(Boolean),
        district: this.queryDistrict.filter(Boolean),
        classify: this.querySite.filter(Boolean),
        province: this.queryRegion.filter(Boolean),
        channel: this.queryChannel.filter(Boolean),
        network_type:this.queryType.filter(Boolean),

        time_type: this.queryTimeRangeType,

        b_time: this.queryTimeRange?.[0]?.toLocaleString?.('sv-SE'),
        e_time: this.queryTimeRange?.[1]?.toLocaleString?.('sv-SE'),

        per_page: this.queryPageSize,
        page: this.queryPageNum,
      }
    })

    this.tableData = data
    this.total = total
  }

  async loadAgentOptions() {
    const data = await Remote.get().http('/admin/api/operation/get_agent')

    this.agentOptions = data
  }

  async loadDistrictOptions() {
    const { district, classify, channel, area } = await Remote.get().http('/admin/api/operation/get_district')

    this.districtOptions = district
    this.channelOptions = channel
    this.siteOptions = classify
    this.regionOptions = area
  }

  @Watch('queryAgent')
  @Watch('queryDistrict')
  async loadSupervisorOptions() {
    const data = await Remote.get().http('/admin/api/operation/get_supevisor', { params: { agent_id: this.queryAgent, district: this.queryDistrict.join(',')}})

    this.supervisorOptions = data
  }

  async loadPartnerOptions() {
    const data = await Remote.get().http('/admin/api/operation/get_partner')

    this.partnerOptions = data
  }

  exportSupervisorData() {
    this.exportData('/admin/api/operation/index_order_list')
  }

  exportNetworkData() {
    this.exportData('/admin/api/operation/export_index_network_list')
  }

  async exportData(path) {
    let url = await Remote.get().http(path, {
      params: {
        agent_id: this.queryAgent.filter(Boolean),
        supervisor_id: this.querySupervisor.filter(Boolean),
        district: this.queryDistrict.filter(Boolean),
        classify: this.querySite.filter(Boolean),
        province: this.queryRegion.filter(Boolean),
        channel: this.queryChannel.filter(Boolean),
        network_type:this.queryType.filter(Boolean),


        time_type: this.queryTimeRangeType,

        b_time: this.queryTimeRange?.[0]?.toLocaleString?.('sv-SE'),
        e_time: this.queryTimeRange?.[1]?.toLocaleString?.('sv-SE'),

        per_page: this.queryPageSize,
        page: this.queryPageNum,

        is_export: 1,
      }
    })

    if (typeof url !== 'string') {
      if (typeof Blob !== 'undefined' && typeof URL?.createObjectURL === 'function') {
        try {
          const blob = new Blob([ JSON.stringify(url) ], { type: 'application/json' })

          url = URL.createObjectURL(blob)
        } catch (err) {
          this.$message.error('导出失败')

          return
        }
      }
    }

    Remote.get().download(url)
  }

  render() {
    return (
      <Container class="supervisor-order-data-main base-main">
        <Breadcrumb />

        <Main>
          <Card>
            <Row gutter={ 24 }>
              <Col span={ 2 }>
                <label for="search-agent">代理商名称</label>
              </Col>
              <Col span={ 4 }>
                <Select label="代理商名称" placeholder="请输入" id="search-agent" filterable multiple value={ this.queryAgent } onInput={ e => (this.queryAgent = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.agentOptions.map(agent => (
                      <Option value={ agent.id } label={ agent.name } key={ agent.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-channel">渠道</label>
              </Col>
              <Col span={ 4 }>
                <Select label="渠道" placeholder="请输入" id="search-channel" multiple value={ this.queryChannel } onInput={ e => (this.queryChannel = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.channelOptions.map(channel => (
                      <Option value={ channel.id } label={ channel.name } key={ channel.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-district">片区</label>
              </Col>
              <Col span={ 4 }>
                <Select label="片区" placeholder="请输入" id="search-district" multiple value={ this.queryDistrict } onInput={ e => (this.queryDistrict = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.districtOptions.map(district => (
                      <Option value={ district.id } label={ district.name } key={ district.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-region">地域</label>
              </Col>
              <Col span={ 4 }>
                <Select label="地域" placeholder="请输入" id="search-region" multiple value={ this.queryRegion } onInput={ e => (this.queryRegion = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.regionOptions.map(region => (
                      <Option value={ region.id } label={ region.name } key={ region.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-supervisor">经销点名称</label>
              </Col>
              <Col span={ 4 }>
                <Select label="经销点名称" placeholder="请输入" id="search-supervisor" filterable multiple reserve-keyword value={ this.querySupervisor } onInput={ e => (this.querySupervisor = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.supervisorOptions.map(supervisor => (
                      <Option value={ supervisor.id } label={ supervisor.name } key={ supervisor.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-site">投放场景</label>
              </Col>
              <Col span={ 4 }>
                <Select label="投放场景" placeholder="请输入" id="search-site" multiple value={ this.querySite } onInput={ e => (this.querySite = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.siteOptions.map(site => (
                      <Option value={ site.id } label={ site.name } key={ site.id } />
                    ))
                  }
                </Select>
              </Col>

               <Col span={ 2 }>
                <label for="search-type">设备类型</label>
              </Col>
              <Col span={ 4 }>
                <Select label="设备类型" placeholder="请输入" id="search-type" multiple value={ this.queryType } onInput={ e => (this.queryType = e,console.log('e',e)) } suffix-icon="el-icon-search" clearable>
                  {
                    this.typeOptions.map(type => (
                      <Option value={ type.id } label={ type.name } key={ type.id } />
                    ))
                  }
                </Select>
              </Col>

              {/* <Col span={ 2 }>
                <label for="search-partner">合伙人</label>
              </Col>
              <Col span={ 4 }>
                <Select label="合伙人" placeholder="请输入" id="search-partner" value={ this.queryPartner } onInput={ e => (this.queryPartner = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.partnerOptions.map(partner => (
                      <Option value={ partner.id } label={ partner.name } key={ partner.id } />
                    ))
                  }
                </Select>
              </Col> */}
            </Row>

            <Row>
              <Col span={ 2 }>
                <label for="search-timerange">时间段</label>
              </Col>
              <Col span={ 10 }>
                <DatePicker type="daterange" value={ this.queryTimeRange } onInput={ e => (this.queryTimeRange  = e) } range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm:ss" default-time={[ '00:00:00', '00:00:00' ]}  style="margin-right: 1em" />
                <RadioGroup value={ this.queryTimeRangeType } onInput={ e => (this.queryTimeRangeType = + e) } size="small">
                  <RadioButton label={ 1 }>今日</RadioButton>
                  <RadioButton label={ 2 }>7日</RadioButton>
                  <RadioButton label={ 3 }>30日</RadioButton>
                  <RadioButton label={ 4 }>90日</RadioButton>
                </RadioGroup>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row slot="header">
              <Col span={ 2 }>
                <span class="label">经销点数据</span>
              </Col>
              <Col span={ 8 } offset={ 14 } style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={ this.exportSupervisorData.bind(this) }>导出数据(经销点)</Button>
                <Button type="primary" onClick={ this.exportNetworkData.bind(this) }>导出数据(网点)</Button>
              </Col>
            </Row>
            <Table data={ this.tableData } stripe>
              <TableColumn label="经销点名称" prop="name" />
              <TableColumn label="场地类型" prop="classify_type" />
              <TableColumn label="网点数量" prop="network" />
              <TableColumn label="设备数量" prop="device" />
              <TableColumn label="订单数量" prop="pay_num_total" />
              <TableColumn label="订单金额" prop="total_pay" />
              <TableColumn label="均收" prop="total_pay_arg" />
              <TableColumn label="转化率" prop="uv_rate" />
              <TableColumn label="坐下人数" prop="sit_total" />
              <TableColumn label="订单转换率" prop="order_rate" />
              <TableColumn label="退款数" prop="refund_num" />
              <TableColumn label="退款金额" prop="refund_amount" />
            </Table>

            <div class="pagination block">
              <Pagination
                pageSize={ this.queryPageSize }
                total={ this.total }
                layout="total, prev, pager, next, sizes, jumper"

                on-current-change={ e => (this.queryPageNum = e) }
                on-size-change={ e => (this.queryPageSize = e) }
                on-prev-click={ e => (this.queryPageNum = e) }
                on-next-click={ e => (this.queryPageNum = e) }
              />
            </div>

          </Card>
        </Main>
      </Container>
    )
  }
}
