import axios from 'axios'

// eslint-disable-next-line import/first
import { defaults } from 'lodash-es'

export
class Remote {
  /** @return {Remote} */
  static get(name) {
    return (
      this._instances?.[name] ??
      (this._instance ??= new this())
    )
  }

  static register(name, remote) {
    (this._instances ??= {})[name] ??= remote
  }

  http(url, options) {
    options = defaults({ url }, options)


    return axios(options).then(
      response => {
        // if (!response) {
        //   return []
        // }

        if (response.status < 200 || 300 <= response.status) {
          const error = new Error(response.statusText)

          error.code = response.status

          throw error
        }

        const { code, msg: message, data } = response.data

        if (!code) {
          return response.data
        }

        // eslint-disable-next-line yoda
        if (200 <= code && code <= 299) {
          return data
        }

        const error = new Error(message)

        error.code = code

        throw error
      },

      response => {
        let error = response

        if (response.data) {
          const { code, msg: message } = response.data

          // eslint-disable-next-line yoda
          if (code < 200 || 299 < code) {
            error = new Error(message)

            error.code = code
          }
        }

        throw error
      }
    )
  }

  download(url) {
    const anchor = document.createElement('a')

    anchor.download = ''
    anchor.href = url

    document.body.append(anchor)

    anchor.click()
    anchor.remove()
  }
}
